<template>
  <DefaultLayout>
    <router-view></router-view>
  </DefaultLayout>
</template>

<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'Import',
  components: {
    DefaultLayout: () => import('@/layouts/Default.vue'),
  },
  methods: {
    ...mapMutations('globals', ['SET_TITLE']),
  },
  created() {
    this.SET_TITLE('header.importOrders');
  },
});
</script>

<style></style>
